import { loadCatch } from '@/utils/utils';
import { getUserRights } from '@/utils/rights/index';
import MainContainer from '../views/main/MainContainer.vue';

const routes = [
  {
    path: '/',
    redirect: { name: 'ReviewedBusinessList' },
  },
  {
    path: '/main',
    name: 'Main',
    component: MainContainer,
    children: [
      // {
      //   path: 'agentIndex',
      //   name: 'AgentIndex',
      //   title: '首页',
      //   icon: 'sx-icon-shouye',
      //   component: () => import(/* webpackChunkName: "index" */ '../views/shopIndex/shopIndex.vue').catch(loadCatch),
      // },
      // {
      //   path: 'noticeDetail',
      //   name: 'NoticeDetail',
      //   show: false,
      //   component: () => import(/* webpackChunkName: "index" */ '../views/notice/detail.vue').catch(loadCatch),
      // },
      {
        path: 'business',
        name: 'Business',
        title: '商户',
        icon: 'sx-icon-shanghu',
        component: () => import('../views/business/index.vue').catch(loadCatch),
        children: [
          {
            path: 'merchantToBeAudited',
            name: 'merchantToBeAuditedBusinessList',
            title: '待审核商户',
            rights: 'p_t-shop-info_list',
            childRights: ['p_t-shop-config_update@'],
            component: () => import('../views/business/merchantToBeAudited.vue').catch(loadCatch),
          },
          {
            path: 'reviewed',
            name: 'ReviewedBusinessList',
            title: '商户列表',
            rights: 'p_t-shop-info_list',
            childRights: ['p_t-shop-config_update@修改补贴配送费'],
            component: () => import('../views/business/reviewed.vue').catch(loadCatch),
          },
          {
            path: 'commodityDetails',
            name: 'commodityDetails',
            hidden: true,
            title: '商品详情',
            component: () => import(/* webpackChunkName: "goods" */ '../views/business/commodityDetails.vue').catch(loadCatch),
          },
          {
            path: 'goodsList',
            name: 'goodsList',
            hidden: true,
            title: '商品列表',
            component: () => import(/* webpackChunkName: "goods" */ '../views/goods/takeOutGoodsList.vue').catch(loadCatch),
          },
          {
            path: 'goodsDetail',
            name: 'GoodsDetail',
            hidden: true,
            title: '商品详情',
            component: () => import(/* webpackChunkName: "goods" */ '../views/goods/goodsDetail.vue').catch(loadCatch),
          },
          {
            path: 'editMerchants',
            name: 'editMerchants',
            hidden: true,
            title: '编辑商户',
            component: () => import(/* webpackChunkName: "goods" */ '../views/business/editMerchants.vue').catch(loadCatch),
          },
          {
            path: 'verificationMerchant',
            name: 'verificationMerchant',
            hidden: true,
            title: '待审核编辑和审核',
            component: () => import(/* webpackChunkName: "goods" */ '../views/business/verificationMerchant.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'settlementFinance',
        name: 'SettlementFinance',
        title: '提现',
        icon: 'sx-icon-shanghu',
        component: () => import('../views/settlementFinance/index.vue').catch(loadCatch),
        children: [
          {
            path: 'settlementFinanceShop',
            name: 'SettlementFinanceShop',
            title: '商家提现',
            rights: 'g_withdraw_queryWithdrawShopNames',
            childRights: ['g_withdraw_export@导出数据', 'p_withdraw_verifyShopState@打款审核'],
            component: () => import('../views/settlementFinance/settlementFinanceShop.vue').catch(loadCatch),
          },
          {
            path: 'settlementFinanceAgent',
            name: 'SettlementFinanceAgent',
            title: '代理提现',
            rights: 'g_agent_withdraw_queryWidthdrawList',
            component: () => import('../views/settlementFinance/settlementFinanceAgent.vue').catch(loadCatch),
          },
          {
            path: 'settlementFinanceRider',
            name: 'SettlementFinanceRider',
            title: '骑手提现',
            component: () => import('../views/settlementFinance/settlementFinanceRider.vue').catch(loadCatch),
          },
          // settlementFinanceUser
          {
            path: 'settlementFinanceUser',
            name: 'SettlementFinanceUser',
            title: '用户提现',
            component: () => import('../views/settlementFinance/settlementFinanceUser.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'user',
        name: 'User',
        title: '用户',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/index.vue').catch(loadCatch),
        children: [
          {
            path: 'userList',
            name: 'UserList',
            title: '用户列表',
            rights: 'g_t-user_page',
            component: () => import(/* webpackChunkName: "user" */ '../views/user/userList.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'systemManager',
        name: 'SystemManager',
        title: '系统管理',
        hidden: true,
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "systemManager" */ '../views/systemManager/index.vue').catch(loadCatch),
        children: [
          {
            path: 'department',
            name: 'department',
            title: '部门管理',
            rights: 'g_t-spread-sys-dept_page',
            childRights: ['p_t-spread-sys-dept_add@新增', 'p_t-spread-sys-dept_update@编辑', 'p_t-spread-sys-dept_delete@删除'],
            component: () => import(/* webpackChunkName: "systemManager" */ '../views/systemManager/department.vue').catch(loadCatch),
          },
          {
            path: 'rolePermissions',
            name: 'rolePermissions',
            title: '角色权限',
            role: 'admin',
            component: () => import(/* webpackChunkName: "systemManager" */ '../views/systemManager/rolePermissions.vue').catch(loadCatch),
          },
          {
            path: 'userManagement',
            name: 'userManagement',
            title: '用户管理',
            role: 'admin',
            component: () => import(/* webpackChunkName: "systemManager" */ '../views/systemManager/userLists.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'operationLog',
        name: 'OperationLog',
        title: '操作日志',
        hidden: true,
        icon: 'sx-icon-zu1281-copy',
        rights: 'p_operation_log_query',
        childRights: ['p_operation_log_export@导出'],
        component: () => import(/* webpackChunkName: "operationLog" */ '../views/operationLog/operationLog.vue').catch(loadCatch),
      },
      {
        path: 'checkAccount',
        name: 'checkAccount',
        title: '清算查账',
        icon: 'sx-icon-zu1281-copy',
        rights: 'p_liquidation_list',
        childRights: ['p_liquidation_list_export@导出'],
        component: () => import(/* webpackChunkName: "checkAccounts" */ '../views/account/checkAccount.vue').catch(loadCatch),
      },
      {
        path: 'buyRebate',
        name: 'buyRebate',
        title: '松鼠返利',

        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/index.vue').catch(loadCatch),
        children: [
          // groupManagement
          {
            path: 'groupManagement',
            name: 'GroupManagement',
            title: '团队管理',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/groupManagement.vue').catch(loadCatch),
          },
          // generalAgent
          {
            path: 'generalAgent',
            name: 'GeneralAgent',
            title: '普通代理列表',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/generalAgent.vue').catch(loadCatch),
          },
          {
            path: 'groupManagementDetail',
            name: 'GroupManagementDetail',
            title: '团队管理详情',
            hidden: 'true',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/groupManagementDetail.vue').catch(loadCatch),
          },
          {
            path: 'shopList',
            name: 'ShopList',
            title: '返利商户列表',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/shopList.vue').catch(loadCatch),
          },
          {
            path: 'userList',
            name: 'BuyRebateUserList',
            title: '返利用户列表',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/userList.vue').catch(loadCatch),
          },
          {
            path: 'couponList',
            name: 'CouponList',
            title: '三方优惠券',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/couponList.vue').catch(loadCatch),
          },
          {
            path: 'addCoupon',
            name: 'addCoupon',
            title: '添加优惠券',
            hidden: 'true',
            component: () => import(/* webpackChunkName: "buyRebate" */ '../views/buyRebate/addCoupon.vue').catch(loadCatch),
          },
        ],
      },
      // {
      //   path: 'dailyMerchantStatistics',
      //   name: 'dailyMerchantStatistics',
      //   title: '每日商户数量统计',
      //   icon: 'sx-icon-zu1281-copy',
      //   rights: 'g_t-platform-shop-statics_count',
      //   component: () => import(/* webpackChunkName: "dailyMerchantStatistics" */ '../views/dailyMerchantStatistics/dailyMerchantStatistics.vue').catch(loadCatch),
      // },
      {
        path: 'agnetUser',
        name: 'agnetUser',
        title: '代理商',
        icon: 'sx-icon-zu1280-copy',
        component: () => import(/* webpackChunkName: "agnetUser" */ '../views/agnetUser/index.vue').catch(loadCatch),
        children: [
          {
            path: 'AgnetUserLsit',
            name: 'AgnetUserLsit',
            title: '代理商列表',
            component: () => import(/* webpackChunkName: "bill" */ '../views/agnetUser/AgnetUserList.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'schoolGroup',
        name: 'schoolGroup',
        title: '校园商圈',
        icon: 'sx-icon-zu1280-copy',
        component: () => import(/* webpackChunkName: "agnetUser" */ '../views/schoolGroup/index.vue').catch(loadCatch),
        children: [
          {
            path: 'schoolManage',
            name: 'schoolManage',
            title: '商圈管理',
            component: () => import(/* webpackChunkName: "schoolGroupShop" */ '../views/schoolGroup/schoolManage.vue').catch(loadCatch),
          },
          {
            path: 'applyRider',
            name: 'applyRider',
            title: '申请骑手',
            component: () => import(/* webpackChunkName: "applyRider" */ '../views/schoolGroup/applyRider.vue').catch(loadCatch),
          },
        ],
      },
      // {
      //   path: 'agent',
      //   name: 'agent',
      //   title: '代理商列表',
      //   icon: 'sx-icon-shanghu',
      //   component: () => import(/* webpackChunkName: "agent" */ '../views/agent/index.vue').catch(loadCatch),
      //   children: [
      //     {
      //       path: 'agentList',
      //       name: 'agentList',
      //       title: '代理商列表',
      //       rights: 'p_t-agent_list',
      //       component: () => import(/* webpackChunkName: "agentList" */ '../views/agent/agentList.vue').catch(loadCatch),
      //     },
      //     {
      //       path: 'agentAccount',
      //       name: 'agentAccount',
      //       title: '代理商账户',
      //       rights: 'p_t-agent_fund_list',
      //       component: () => import(/* webpackChunkName: "agentAccount" */ '../views/agent/agentAccount.vue').catch(loadCatch),
      //     },
      //   ],
      // },
      {
        path: 'algroupPurchase',
        name: 'algroupPurchase',
        title: '联联团购',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "agnetUser" */ '../views/algroupPurchase/index.vue').catch(loadCatch),
        children: [
          {
            path: 'algroupPurchaseList',
            name: 'AlgroupPurchaseList',
            title: '联联团购',
            component: () => import(/* webpackChunkName: "bill" */ '../views/algroupPurchase/algroupPurchaseList.vue').catch(loadCatch),
          },
          {
            path: 'addgroupBuyingGoods',
            name: 'AddGroupBuyingGoods',
            title: '新增商品',
            hidden: true,
            component: () => import(/* webpackChunkName: "groupBuying" */ '../views/algroupPurchase/addgroupBuyingGoods.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'groupManagement',
        name: 'groupManagement',
        title: '团购管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "agnetUser" */ '../views/groupManagement/index.vue').catch(loadCatch),
        children: [
          {
            path: 'groupManagementlist',
            name: 'groupManagementlist',
            title: '团购审核',
            component: () => import(/* webpackChunkName: "groupBuying" */ '../views/groupManagement/groupManagementlist.vue').catch(loadCatch),
          },
          {
            path: 'sgroupManagement',
            name: 'sgroupManagement',
            title: '团购审核',
            hidden: true,
            component: () => import(/* webpackChunkName: "groupBuying" */ '../views/groupManagement/sgroupManagement.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'branch',
        name: 'branch',
        title: '分公司',
        icon: 'sx-icon-shanghu',
        component: () => import('../views/branch/index.vue').catch(loadCatch),
        children: [
          {
            path: 'branchList',
            name: 'GranchList',
            title: '分公司列表',
            component: () => import('../views/branch/branchList.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'staffList',
        name: 'staffList',
        title: '员工列表',
        icon: 'sx-icon-zu1281-copy',
        rights: 'g_staff_query',
        childRights: ['p_staff_add@新增员工', 'g_staff_mch_queryShopByStaffId@商家列表'],
        component: () => import(/* webpackChunkName: "staffList" */ '../views/staff/staffList.vue').catch(loadCatch),
      },
      {
        path: 'advertising',
        name: 'Advertising',
        title: '广告',
        icon: 'sx-icon-zu1281-copy',
        component: () => import(/* webpackChunkName: "aduserList" */ '../views/advertising/index.vue').catch(loadCatch),
        children: [
          {
            path: 'accountList',
            name: 'AccountList',
            title: '开户列表',

            component: () => import('../views/advertising/accountList.vue').catch(loadCatch),
          },
          {
            path: 'advertisingList',
            name: 'AdvertisingList',
            title: '广告列表',
            component: () => import('../views/advertising/advertisingList.vue').catch(loadCatch),
          },
          {
            path: 'accountAudit',
            name: 'AccountAudit',
            title: '审核详情',
            hidden: true,
            component: () => import('../views/advertising/accountAudit.vue').catch(loadCatch),
          },
          {
            path: 'createAd',
            name: 'CreateAd',
            title: '制作广告',
            hidden: true,
            component: () => import(/* webpackChunkName: "noticeList" */ '../views/advertising/createAd.vue').catch(loadCatch),
          },
        ],
      },

      {
        path: 'order',
        name: 'order',
        title: '订单',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/index.vue').catch(loadCatch),
        children: [
          {
            path: 'takeAwayOrder',
            name: 'takeAwayOrder',
            title: '外卖订单',
            rights: 'g_t-order_page',
            component: () => import(/* webpackChunkName: "takeAwayOrder" */ '../views/order/takeAwayOrder.vue').catch(loadCatch),
          },
          {
            path: 'eatinOrder',
            name: 'eatinOrder',
            title: '到店订单',
            rights: 'p_t-scan-order_list',
            component: () => import(/* webpackChunkName: "eatinOrder" */ '../views/order/eatinOrder.vue').catch(loadCatch),
          },
          {
            path: 'groupOrder',
            name: 'groupOrder',
            title: '团购订单',
            rights: 'p_t-scan-order_list',
            component: () => import(/* webpackChunkName: "groupOrder" */ '../views/order/groupOrder.vue').catch(loadCatch),
          },
          // {
          //   path: 'buyRebate',
          //   name: 'buyRebate',
          //   title: '返利订单',
          //   component: () => import(/* webpackChunkName: "business" */ '../views/order/buyRebate.vue').catch(loadCatch),
          //   redirect: 'buyRebate/buyRebateOrder',
          //   children: [
          //     {
          //       path: 'buyRebateOrder',
          //       name: 'BuyRebateOrder',
          //       component: () => import(/* webpackChunkName: "business" */ '../views/order/buyRebateOrder.vue').catch(loadCatch),
          //     },
          //     {
          //       path: 'buyRebateCardOrder',
          //       name: 'BuyRebateCardOrder',
          //       component: () => import(/* webpackChunkName: "business" */ '../views/order/buyRebateCardOrder.vue').catch(loadCatch),
          //     },
          //   ],
          // },
          {
            path: 'rebateOrder',
            name: 'rebateOrder',
            title: '返利订单',
            rights: 'p_t-rebate-order_list',
            component: () => import(/* webpackChunkName: "rebateOrder" */ '../views/order/rebateOrder.vue').catch(loadCatch),
          },
          {
            path: 'alOrder',
            name: 'alOrder',
            title: '联联订单',
            rights: 'p_t-rebate-order_list',
            component: () => import(/* webpackChunkName: "rebateOrder" */ '../views/order/alOrder.vue').catch(loadCatch),
          },
          {
            path: 'toauditOrder',
            name: 'toauditOrder',
            title: '待审核订单',
            rights: 'p_t-rebate-order_list',
            component: () => import(/* webpackChunkName: "rebateOrder" */ '../views/order/toauditOrder.vue').catch(loadCatch),
          },
          {
            path: 'runOrder',
            name: 'RunOrder',
            title: '跑腿订单',
            rights: 'p_t-run-order_list',
            component: () => import(/* webpackChunkName: "runOrder" */ '../views/order/runOrder.vue').catch(loadCatch),
          },
          {
            path: 'revokeOrder',
            name: 'RevokeOrder',
            title: '配送单撤销',
            rights: 'p_t-revoke-order_list',
            component: () => import(/* webpackChunkName: "order" */ '../views/order/revokeOrder.vue').catch(loadCatch),
          },
        ],
      },

      {
        path: 'notice',
        name: 'Notice',
        title: '公告',
        icon: 'sx-icon-lujing455-copy',
        rights: 'p_t-system-notice_list',
        childRights: ['p_t-system-notice_add@添加公告', 'p_t-system-notice_update@编辑公告'],
        component: () => import(/* webpackChunkName: "noticeList" */ '../views/notice/noticeList.vue').catch(loadCatch),
      },
      {
        path: 'warnShop',
        name: 'warnShop',
        title: '预警店铺',
        hidden: true,
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "warnShop" */ '../views/warnShop/index.vue').catch(loadCatch),
        children: [
          {
            path: 'warnShopList',
            name: 'warnShopList',
            title: '预警列表',
            component: () => import(/* webpackChunkName: "warnShopList" */ '../views/warnShop/warnShopList.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'editNotice',
        name: 'editNotice',
        hidden: true,
        component: () => import(/* webpackChunkName: "noticeList" */ '../views/notice/editNotice.vue').catch(loadCatch),
      },
      {
        path: 'miniapp',
        name: 'Miniapp',
        title: '小程序管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "index" */ '../views/miniapp/index.vue').catch(loadCatch),
        children: [
          {
            path: 'templateList',
            name: 'TemplateList',
            title: '模板列表',
            component: () => import(/* webpackChunkName: "templateList" */ '../views/miniapp/templateList.vue').catch(loadCatch),
          },
          {
            path: 'miniappList',
            name: 'MiniappList',
            title: '小程序列表',
            component: () => import(/* webpackChunkName: "miniappList" */ '../views/miniapp/miniappList.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'settting',
        name: 'settting',
        title: '配送设置',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "index" */ '../views/setting/setting.vue').catch(loadCatch),
      },
      {
        path: 'applyList',
        name: 'ApplyList',
        title: '进件管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "index" */ '../views/apply/applyList.vue').catch(loadCatch),
      },
      {
        path: 'incomingPay',
        name: 'IncomingPay',
        title: '进件支付管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "index" */ '../views/incomingPay/index.vue').catch(loadCatch),
        children: [
          {
            path: 'equipmentBoxManager',
            name: 'EquipmentBoxManager',
            title: '设备管理',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/equipmentBoxManager.vue').catch(loadCatch),
          },
          {
            path: 'equipmentUsage',
            name: 'EquipmentUsage',
            title: '设备使用情况',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/equipmentUsage/equipmentUsage.vue').catch(loadCatch),
          },
          {
            path: 'addOrEditEquipentUsage',
            name: 'AddOrEditEquipentUsage',
            hidden: true,
            title: '添加设备使用情况',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/equipmentUsage/addOrEditEquipentUsage.vue').catch(loadCatch),
          },
          {
            path: 'equipmentUsageDetail',
            name: 'EquipmentUsageDetail',
            hidden: true,
            title: '设备使用详情',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/equipmentUsage/equipmentUsageDetail.vue').catch(loadCatch),
          },
          {
            path: 'equipmentBatchDetail',
            name: 'EquipmentBatchDetail',
            title: '设备批次详情',
            hidden: true,
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/equipmentBatchDetail.vue').catch(loadCatch),
          },
          {
            path: 'agentData',
            name: 'AgentData',
            title: '代理商数据',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/agentData.vue').catch(loadCatch),
          },
          {
            path: 'agentList',
            name: 'AgentList',
            title: '代理商列表',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/agentList/agentList.vue').catch(loadCatch),
          },
          {
            path: 'agentDetail',
            name: 'AgentDetail',
            hidden: true,
            title: '代理商详情',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/agentList/agentDetail.vue').catch(loadCatch),
          },
          {
            path: 'agentEdit',
            name: 'AgentEdit',
            hidden: true,
            title: '代理商编辑',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/agentList/agentEdit.vue').catch(loadCatch),
          },
          {
            path: 'agentWithdrawal',
            name: 'AgentWithdrawal',
            title: '代理商提现申请',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/agentWithdrawal.vue').catch(loadCatch),
          },
          {
            path: 'merchantList',
            name: 'MerchantList',
            title: '商户列表',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/merchantList.vue').catch(loadCatch),
          },
          {
            path: 'merchantDetail',
            name: 'MerchantDetail',
            title: '商户详情',
            hidden: true,
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/merchantList/merchantDetail.vue').catch(loadCatch),
          },
          {
            path: 'transactionData',
            name: 'TransactionData',
            title: '交易详情',
            hidden: true,
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/merchantList/transactionData.vue').catch(loadCatch),
          },
          {
            path: 'paymentCodesList',
            name: 'PaymentCodesList',
            title: '收款码列表',
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/paymentCodesList.vue').catch(loadCatch),
          },
          {
            path: 'payCodeTable',
            name: 'PayCodeTable',
            title: '收款码列表',
            hidden: true,
            component: () => import(/* webpackChunkName: "incomingPay" */ '../views/incomingPay/paymentCodesList/payCodeTable.vue').catch(loadCatch),
          },
        ],
      },
      {
        path: 'thirdPartyChannel',
        name: 'ThirdPartyChannel',
        title: '三方渠道管理',
        icon: 'sx-icon-shanghu',
        component: () => import(/* webpackChunkName: "thirdPartyChannel" */ '../views/thirdPartyChannel/index.vue').catch(loadCatch),
        children: [
          {
            path: 'channelLists',
            name: 'ChannelLists',
            title: '渠道列表',
            component: () => import(/* webpackChunkName: "thirdPartyChannel" */ '../views/thirdPartyChannel/channelLists/index.vue').catch(loadCatch),
          },
          {
            path: 'orderLists',
            name: 'OrderLists',
            title: '订单列表',
            component: () => import(/* webpackChunkName: "thirdPartyChannel" */ '../views/thirdPartyChannel/orderLists/index.vue').catch(loadCatch),
          },
          {
            path: 'orderDetial/:id',
            name: 'OrderDetail',
            title: '订单详情',
            props: true,
            hidden: true,
            component: () => import(/* webpackChunkName: "thirdPartyChannel" */ '../views/thirdPartyChannel/orderLists/orderDetail.vue').catch(loadCatch),
          },
          {
            path: 'checkPendingLists',
            name: 'CheckPendingLists',
            title: '待审核订单',
            component: () => import(/* webpackChunkName: "thirdPartyChannel" */ '../views/thirdPartyChannel/checkPendingLists/index.vue').catch(loadCatch),
          },
        ],
      },
      // {
      //   path: 'notice',
      //   name: 'Notice',
      //   title: '公告',
      //   icon: 'sx-icon-lujing455-copy',
      //   component: () => import(/* webpackChunkName: "index" */ '../views/notice/list.vue').catch(loadCatch),
      // },
      {
        path: '/',
        redirect: { name: 'ReviewedBusinessList' },
      },
      {
        path: '*',
        redirect: { name: 'ReviewedBusinessList' },
      },
    ],
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  // {
  //   path: '/findPassword',
  //   name: 'FindPassword',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/login/findPassword.vue'),
  // },
];

function deepBuildMenu(routeList) {
  return routeList
    .map((routeItem) => {
      const menuItem = {
        ...routeItem,
        component: undefined,
      };
      if (routeItem.children && routeItem.children.length > 0) {
        menuItem.children = deepBuildMenu(menuItem.children);
      }

      return menuItem;
    })
    .filter((item) => {
      if (!['/', '*'].includes(item.path)) {
        if (item.hidden) return false;
        return true;
      }
      return false;
    });
}

function deepfindMenu(routeList, path, pathList) {
  return routeList.find((routeItem) => {
    const menuItem = {
      path: routeItem.path,
      title: routeItem.title,
      icon: routeItem.icon,
    };
    if (routeItem.children && routeItem.children.length > 0) {
      const end = deepfindMenu(routeItem.children, path, pathList);
      if (end) {
        pathList.push(menuItem);
      }
      return Boolean(end);
    }
    if (routeItem.path === path) {
      pathList.push(menuItem);
    }

    return routeItem.path === path;
  });
}
export const getAllRouteMenu = () => deepBuildMenu(routes.find((item) => item.path === '/main').children);

function getHasRightsMenu(rightsList, menuList) {
  return menuList.filter((menuItem) => {
    if (menuItem.rights) {
      // console.log(rightsList.includes(menuItem.rights), menuItem.rights, rightsList, 'dsfasfsd');
      return rightsList.includes(menuItem.rights);
    }
    if (menuItem.role === 'admin') return false;
    if (menuItem.children) {
      const childrenList = getHasRightsMenu(rightsList, menuItem.children);
      // console.log('ddd', childrenList, menuItem);
      if (childrenList.length === 0) return false;
      // eslint-disable-next-line
      menuItem.children = childrenList;
      return true;
    }
    return true;
  });
}

export const getRouteMenu = () => {
  const userRights = getUserRights();
  const allRouteMenu = getAllRouteMenu();
  if (userRights.isRoot) {
    return allRouteMenu;
  }
  const hasRightsMenu = getHasRightsMenu(userRights.userRights, allRouteMenu);
  console.log(hasRightsMenu, 'hasRightsMenu');
  return hasRightsMenu;
};
export const getRouteByPath = (path) => {
  const routePath = [];
  deepfindMenu(routes.find((item) => item.path === '/main').children, path, routePath);
  return routePath.reverse();
};

// 获取当前用户可授权的 菜单或权限列表
export const getRouteDataByRights = () => {
  let allRootMenuTreeData = getAllRouteMenu();
  // 过滤掉 超级管理员的 专属菜单
  allRootMenuTreeData = allRootMenuTreeData.filter((item) => {
    if (item.role === 'admin') return false;
    if (item.children && item.children.length > 0) {
      item.children = item.children.filter((temp) => temp.role !== 'admin');
    }
    return true;
  });
  return allRootMenuTreeData;
};

export default routes;
