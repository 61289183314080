import {
  addUrlOptionsStr,
  formatDate, formatDateByDate,
  verifyDateInterval, deadTime, formatMoney,
  formatTimeRangeStr,
} from '@/utils';
import smsUtil from '@/utils/smsUtils';
import {
  UPLOAD_DOMAIN, getBaseUrl, getDescFromCode, getWapApiBaseUrl, 
} from '@/config';
import ExportTip from '@/components/common/ExportTip';
import { $p_sendCaptchaSms } from '@/api/common';
import validator from '@base/utils/validator/validator';
import Element from 'element-ui';
import Config, { BinaryConfig } from '@common/config';

// 外部图片
const imgPrefix = (path, defaultPath, options) => {
  if (!path && !defaultPath) {
    return '/img/unset.png';
  }
  if (!path && defaultPath) {
    if (defaultPath.startsWith('data:')) {
      return defaultPath;
    }
    path = defaultPath; // eslint-disable-line
  }
  if (path.startsWith('/img')) {
    return path;
  }
  if (path.startsWith('http://') || path.startsWith('https://')) {
    // 如果图片是完整的图片， 或是项目中的静态图片，则直接返回路径
    return addUrlOptionsStr(path, options);
  }
  return addUrlOptionsStr(`${UPLOAD_DOMAIN}${path}`, options);
};

export default {
  install(Vue) {
    Vue.prototype.$Config = Config;
    Vue.prototype.$BinaryConfig = BinaryConfig;
    Vue.prototype.$imgPrefix = imgPrefix;
    // 格式化日期
    Vue.prototype.$formatDate = formatDate;
    Vue.prototype.$formatDateByDate = formatDateByDate;
    Vue.filter('formatDate', formatDate);
    // 处理查询日期间隔
    Vue.prototype.$verifyDateInterval = verifyDateInterval;
    // 到期时间
    Vue.prototype.$deadTime = deadTime;
    Vue.filter('deadTime', deadTime);
    // 图片
    Vue.filter('imgPrefix', imgPrefix);
    Vue.prototype.$imgPrefix = imgPrefix;
    // 格式化金额
    Vue.prototype.$formatMoney = formatMoney;
    Vue.filter('formatMoney', formatMoney);
    // 导出数据提示框
    Vue.prototype.$exportTips = ExportTip;
    // 获取基本路径
    Vue.prototype.$getBaseUrl = getBaseUrl;

    Vue.prototype.$getDescFromCode = getDescFromCode;
    // eslint-disable-next-line
    Vue.prototype.$formatSendTimeDesc = function (order_content,is_predict) {
      const time = order_content.send_time;
      if (time === 0) {
        if (is_predict < 3) { return '尽快送达'; }
        if (is_predict === 3 || is_predict === 4) { return '已在店内'; }
      }
      if (order_content.send_time_interval) {
        return formatTimeRangeStr(order_content.send_time_interval);
      }
      return this.$formatDate(time, 'MM-dd HH:mm');
    };

    Vue.prototype.$validator = (value, args1, args2) => validator(value, args1, args2).catch((err) => {
      Element.Message.error(err);
      return Promise.reject(err);
    });
  },
};

export const sendTelCode = (phone, appCode) => {
  // 终端区分（shop：商户端 user：用户端）
  // 签名（手机号 + 终端区分 + salt）
  const params = smsUtil({
    appName: appCode,
    phone,
  });
  return $p_sendCaptchaSms(params);
};

export const getQrcodeSrc = (content, size = 200) => `${getWapApiBaseUrl()}/general/v5/tool/qrcode?size=${size}&content=${encodeURIComponent(content)}`;

