import OurConfig from './ourConfig';
import axiosInstance from './request';


export const axiosGet = (url, params, config) => axiosInstance.get(url, { params, ...config });

export const axiosPost = (url, params, config) => axiosInstance.post(url, params, config);

export default {

  // 三餐ajax
  get: (url, params, config) => axiosGet(url, params, config),
  post: (url, params, config) => axiosPost(url, params, config),
  getNoparam: (url) => axiosInstance.get(url),
  // 不提示, 不loading
  getNoTips: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_LOADING | OurConfig.NOT_MESSAGE,
  }),
  postNoTips: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_LOADING | OurConfig.NOT_MESSAGE,
  }),

  // 不提示
  getNoMessage: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_MESSAGE,
  }),
  postNoMessage: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_MESSAGE,
  }),

  // 不Reject , 返回完整resp,
  getNoReject: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_REJECT,
  }),
  postNoReject: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_REJECT,
  }),

  // 不Reject , 返回完整resp 不loading 不 message,
  getNoRejectNoTips: (url, params, config = {}) => axiosGet(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_REJECT | OurConfig.NOT_LOADING | OurConfig.NOT_MESSAGE,
  }),
  postNoRejectNoTips: (url, params, config = {}) => axiosPost(url, params, {
    ...config,
    ourConfig: OurConfig.NOT_REJECT | OurConfig.NOT_LOADING | OurConfig.NOT_MESSAGE,
  }),
  delete: (url, params, config = {}) => axiosInstance.delete(url, { params, ...config }),
};
