// 导出数据请求地址配置
export const exportDataConfig = {
  // 获取店铺的商品列表分页
  DeliverGoods: '/mall/v1/GoodsManage/getShopGoodsList',
  StatisticsDataCommodityTakeaway: '/mall/v1/GoodsSaleSort/listGoodsSaleStatistics',
  StatisticsAccountSumTakeaway: '/statistics/v1/statistics/getMallPlatStatistics',
  StatisticsAccountSumTakeawayDetail: '/statistics/v1/statistics/getMallShopDetailStatistics',
  StatisticsAccountSumToshop: '/statistics/v1/statistics/listConsumePlatStatistics',
  StatisticsAccountSumToshopDetail: '/statistics/v1/statistics/listConsumeShopDetailStatistics',
  StatisticsAccountSumGroup: '/statistics/v1/statistics/getGroupStatistics',
  StatisticsAccountSumGroupDetail: '/statistics/v1/statistics/getShopDetailList',
  'CountFinanceGetcash': '/j/platform/withdraw/queryWidthdrawList',
};
// 产品改需求 前端不显示类型，以下数据未使用
export const exportDataType = [
  {
    type: 1,
    label: '外卖订单',
  },
  {
    type: 2,
    label: '用户数据',
  },
  {
    type: 3,
    label: '会员数据',
  },
  {
    type: 4,
    label: '团购数据',
  },
  {
    type: 5,
    label: '跑腿数据',
  },
  {
    type: 6,
    label: '到店数据',
  },
  {
    type: 7,
    label: '商家统计数据',
  },
  {
    type: 8,
    label: '跑腿统计数据',
  },
  {
    type: 9,
    label: '会员统计数据',
  },
  {
    type: 10,
    label: '商品统计数据',
  },
  {
    type: 11,
    label: '外送账目数据',
  },
  {
    type: 12,
    label: '团购账目数据',
  },
  {
    type: 13,
    label: '到店账目数据',
  },
  {
    type: 14,
    label: '跑腿账目数据',
  },
  {
    type: 15,
    label: '外送账目数据',
  },
  {
    type: 16,
    label: '代理订单统计',
  },
  {
    type: 17,
    label: '总收益',
  },
  {
    type: 18,
    label: '店铺资金',
  },
  {
    type: 19,
    label: '店铺提现',
  },
  {
    type: 20,
    label: '合作店铺',
  },
  {
    type: 21,
    label: '商家入驻导',
  },
  {
    type: 22,
    label: '储值用户',
  },
  {
    type: 23,
    label: '积分中奖',
  },
  {
    type: 24,
    label: '分佣记录',
  },
  {
    type: 25,
    label: '权益卡订单',
  },
  {
    type: 26,
    label: '优惠劵导',
  },
  {
    type: 27,
    label: '二维码',
  },
];
// export default exportDataConfig;
