import api from './api';

// 判断是否在壳子且是oem
export const $sg_checkShellAndOem = () => api.get('/api/Common/checkShellAndOem');

// 查询代理商PC端首页详情
export const $sg_queryIndexDetail = (params) => api.get('/j/agent/index/queryIndexDetail', params);

// 首页查询分润详情
export const $sg_queryProfitDetail = (params) => api.get('/j/agent/index/queryProfitDetail', params);

// 首页获取公告详情
export const $sg_queryNoticeDetail = (params) => api.post('/j/agent/index/queryNoticeDetail', params);

// 获取公告列表
export const $sg_queryNoticeList = (params) => api.postNoMessage('/j/agent/index/queryNoticeDetail', params);

// 查询银行相关
export const $sp_getAccountOpeningQualification = (params) => api.getNoReject('/j/into/params', params);

// 未读公告提示
export const $sp_queryNoticeCount = (params) => api.getNoReject('/j/agent/index/queryNoticeCount', params);

// 更新公告状态
export const $sp_updNotice = (params) => api.getNoReject('/j/agent/notice/updNotice', params);
