import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
import { getUserToken } from '@/global/tokenManager';
import router from '@/router';
import Utf8 from 'crypto-js/enc-utf8';
import Base64 from 'crypto-js/enc-base64';
import { getBaseUrl, PAY_DOAMIN } from '@/config';
import OurConfig from './ourConfig';
// import logger from '@/utils/logger';
// localStorage.setItem('o2o_user_shop_token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzcHJlYWRfaWQiOjEsIm1lbWJlcl9pZCI6MCwibG9naW5fZ3VpZCI6IiIsInNob3BfaWQiOjEwLCJsb2dpbl90ZXJtaW5hbCI6MSwicm9sZSI6MCwidXNlcl9pZCI6MCwiYWdlbnRfc2lkIjo1MCwiaXNzIjoiYXV0aDAiLCJzdXBlcl90ZWwiOiIiLCJ1bml0eV9pZCI6MCwiZXhwIjoxNjEyOTQwMTM2fQ.S1SJPa14_cEXQllc_jVyp2kkQdL41R8sk2Y5uh0VUCA');

let timeout = 20000;

if (process.env.NODE_ENV === 'development') {
  timeout = undefined;
}

let msgShow = false;
function messageError(text) {
  if (msgShow) return;
  msgShow = true;
  Message.error(text);
  setTimeout(() => {
    msgShow = false;
  }, 2000);
}

function signOut() {
  router.push('/login');
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  ourConfig: 0,
  timeout,
});

function requestTimeOutLogPush(error, otherObj = {}) {
  console.table(error, otherObj);
  // console.log('请求超时', error, arguments);
  // const { config } = error;
  // if (otherObj) {
  //   const keys = Object.keys(localStorage).filter((key) => key.indexOf('AMap') === -1);
  //   const localObj = {};
  //   keys.forEach((key) => {
  //     localObj[key] = localStorage.getItem(key);
  //   });
  //   // eslint-disable-next-line no-param-reassign
  //   otherObj.local = JSON.stringify(localObj);
  //   // eslint-disable-next-line no-param-reassign
  //   otherObj.tokens = JSON.stringify({
  //     spread_token: store.getters.getLastPlatToken,
  //     shop_token: store.getters.getLastShopToken,
  //     agent_token: store.getters.getPlatAgentToken || undefined,
  //   });
  // }
  // logger.export_error(error, {
  //   ...config,
  //   ...otherObj,
  // });
}

axios.defaults.paramsSerializer = function paramsSerializer(params) {
  return qs.stringify(params);
};

axiosInstance.interceptors.request.use((request) => {
  const { ourConfig } = request;
  const config = request;

  if (!config.url.startsWith('http') && config.url.startsWith('/p/pay/')) {
    config.baseURL = PAY_DOAMIN;
  }
  // const { url } = config;
  // 输出请求的配置
  // console.log('config', config);
  // auth token

  if (!request.headers.Authorization && getUserToken()) {
    // eslint-disable-next-line
    request.headers.Authorization = `Bearer ${getUserToken()}`
  }
  // loading
  if (!(ourConfig & OurConfig.NOT_LOADING)) {
    // loading(true);
  }
  // config.baseURL = getBaseUrl();

  return request;
});

const splitStr = (str, size) => {
  if (str.length < size) {
    const rs = [];
    rs[0] = str;
    return rs;
  } else {
    const count = Math.ceil(str.length / size);
    const rs = [];
    for (let i = 0; i <= count; i += 1) {
      if (i === 0) {
        rs.push(str.substring(i, size));
      } else if (i > 0 && i < count) {
        rs.push(str.substring(i * size, (i + 1) * size));
      } else {
        rs.push(str.substring(i * size, str.length));
      }
    }
    return rs;
  }
};

const parseResponseData = (response) => {
  const { config } = response;
  if (config.configKey) {
    const { data } = response;
    const content = data.data;
    if (!content) {
      console.log(response);
    }
    const [, contentLen, roundLeng] = config.configKey.split('_');
    const strList = splitStr(content, Number(contentLen) + Number(roundLeng));
    const endContentStr = strList.map((item) => item.substring(0, contentLen)).join('');
    const base64Item = Base64.parse(endContentStr);
    // eslint-disable-next-line
    response.data = JSON.parse(base64Item.toString(Utf8));
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    parseResponseData(response);

    try {
      const resp = response.data;
      const { code, msg } = resp;
      // console.error(response);
      if (code === 1001 && msg && msg.indexOf('平台标识') > -1) {
        requestTimeOutLogPush(response, {
          cookie: document.cookie,
          resp,
        });
      }
    } catch (e) {
      console.log('e');
    }
    const { ourConfig, method } = response.config;
    const resp = response.data;
    const {
      msg, data, result, message,
    } = resp;

    let code = +resp.code;
    if (code < 0) {
      code = 1001;
    }

    const loadingFlag = !(ourConfig & OurConfig.NOT_LOADING);
    const messageFlag = !(ourConfig & OurConfig.NOT_MESSAGE);
    const rejectFlag = !(ourConfig & OurConfig.NOT_REJECT);
    // loading
    if (loadingFlag) {
      // loading(false);
    }
    // console.log(code, resp);
    // 未登录或游客
    if ([1002, 1005, 401, 201].includes(code)) {
      signOut(code);
    }

    const successCodeList = [1000, 1005, 1007, 1003, 0, 200];
    const failCodeLost = [1001, 400, 500];

    if (method === 'get') {
      if (!successCodeList.includes(code)) {
        if (messageFlag) {
          // messageError(msg);
          messageError(msg || message);
        }
        if (!(ourConfig & OurConfig.NOT_REJECT)) {
          return Promise.reject(resp);
        }
      }
    } else if (method === 'post') {
      if (messageFlag && ![1003, 1005].includes(code) && (msg || message)) {
        // message(msg);
        Message.closeAll();
        if (failCodeLost.includes(code)) {
          Message.error(msg || message);
        } else {
          Message.success(msg || message);
        }
      }
    }

    if (!successCodeList.includes(code) && rejectFlag) {
      return Promise.reject(resp);
    }

    if (!rejectFlag) {
      return resp;
    }
    return data || result;
  },
  (error) => {
    // Indicator.close();
    // console.log(error.message);
    if (error.message && error.message.indexOf('timeout of') !== -1) {
      requestTimeOutLogPush(error);
    } else {
      // mandmobileToast('服务器异常，请稍候再试');
      console.log('请求失败', error);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
