<template>
  <el-select
    class="agent-select-search-comp"
    :placeholder="placeholder"
    clearable
    :size="size"
    v-model="value"
    @change="changeSelectAgent"
  >
    <el-option :value="-1" v-if="hasPlat" label="总部"></el-option>
    <el-option :value="-2" v-if="hasAllAgent" label="全部代理"></el-option>
    <el-option
      :value="item.agent_sid"
      v-for="item in agentList"
      :key="item.agent_sid"
      :label="item.agent_name"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: 'AgentSelectComp',
  props: {
    /*     value: {
      default: '',
    }, */
    size: {
      default: 'medium',
    },
    hasPlat: {
      default: false,
    },
    hasAllAgent: {
      default: false,
    },
    placeholder: {
      default: '所属区域',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    agentList() {
      return [];
    },
  },
  methods: {
    changeSelectAgent(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.$store.dispatch('fetchAgentList', false);
  },
};
</script>
<style lang="scss">
.agent-select-search-comp {
  display: inline-block;
}
</style>
