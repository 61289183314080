/**
 * 文件的处理类
 * 对图片进行裁剪 压缩
 * 将图片按照固定宽高裁剪 或者根据最大最小宽高范围裁剪
 */
export default class FileUtils {
  static getFileBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = reject;
    });
  }

  static Base64ToImage(base64Str) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = base64Str;
      image.onload = () => {
        resolve(image);
      };
      image.onerror = reject;
    });
  }

  static computeCutParams(imgWidth, imgHeight, endWidth, endHeight, model) {
    if (!model) {
      throw new Error('model required');
    }
    let width;
    let height;
    let startX = 0;
    let startY = 0;
    const rate = endWidth / endHeight;
    if ((imgWidth / rate) > imgHeight) {
      // 高度不够
      height = imgHeight;
      width = parseInt(imgHeight * rate, 10);
    } else {
      width = imgWidth;
      height = parseInt(imgWidth / rate, 10);
    }
    if (model === 'center') {
      startX = parseInt((imgWidth - width) / 2, 10);
      startY = parseInt((imgHeight - height) / 2, 10);
    }
    return {
      startX,
      startY,
      width,
      height,
    };
  }

  static cutImageInRangeForWidth(imgWidth, imgHeight, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight) {
    let width;
    let height; // 使用的图片高度
    let endWidth;
    let endHeight; // 最终图片高度
    // 先 确认宽度 切 高度
    if (imgWidth < maxEndWidth) {
      // 宽度符合要求 切无需缩放
      width = imgWidth;
      endWidth = imgWidth;
      if (imgHeight < maxEndHeight) {
        // 高度也符合要求 无需缩放
        height = imgHeight;
        endHeight = imgHeight;
      } else {
        // 高度 需要裁剪 按最大允许高度裁剪
        height = maxEndHeight;
        endHeight = maxEndHeight;
      }
    } else if (imgWidth > maxEndWidth) {
      // 宽度需要缩小
      // 将宽度先缩放到最大宽度
      const widthRate = imgWidth / maxEndWidth;
      const tempEndHeight = imgHeight / widthRate;
      width = imgWidth;
      endWidth = maxEndWidth;
      if (tempEndHeight > minEndHeight && tempEndHeight < maxEndHeight) {
        // 高度在范围之类
        height = imgHeight;
        endHeight = tempEndHeight;
      } else if (tempEndHeight < minEndHeight) {
        // 按照宽度 剪切 高度不够
        // 切换模式 按照高度 剪切
        throw new Error('图片大小不足');
      } else {
        // 高度需要裁剪
        height = parseInt(widthRate * maxEndHeight, 10);
        endHeight = maxEndHeight;
      }
    }

    return {
      width,
      height,
      endWidth,
      endHeight,
    };
  }

  static cutImageInRangeForHeight(imgWidth, imgHeight, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight) {
    let width;
    let height; // 使用的图片高度
    let endWidth;
    let endHeight; // 最终图片高度
    // 确认高度 切 宽度
    if (imgHeight < maxEndHeight) {
      // 高度符合要求 切无需缩放
      height = imgHeight;
      endHeight = imgHeight;
      if (imgWidth < maxEndWidth) {
        // 宽度也符合要求 无需缩放
        width = imgWidth;
        endWidth = imgWidth;
      } else {
        // 宽度 需要裁剪 按最大允许宽度裁剪
        width = maxEndWidth;
        endWidth = maxEndWidth;
      }
    } else if (imgHeight > maxEndHeight) {
      // 高度需要缩小
      // 将高度先缩放到最大高度
      const heightRate = imgHeight / maxEndHeight;
      const tempEndWidth = imgWidth / heightRate;
      height = imgHeight;
      endHeight = maxEndHeight;
      if (tempEndWidth > minEndWidth && tempEndWidth < maxEndWidth) {
        // 宽度在范围之类
        width = imgWidth;
        endWidth = tempEndWidth;
      } else if (tempEndWidth < minEndWidth) {
        // 按照宽度 剪切 高度不够
        // 切换模式 按照高度 剪切
        throw new Error('图片大小不足');
      } else {
        // 高度需要裁剪
        width = parseInt(heightRate * maxEndWidth, 10);
        endWidth = maxEndWidth;
      }
    }

    return {
      width,
      height,
      endWidth,
      endHeight,
    };
  }

  static computeRangCutParams(imgWidth, imgHeight, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight, model) {
    if (!model) {
      throw new Error('model required');
    }

    let startX = 0;
    let startY = 0;
    let width;
    let height; // 使用的图片高度
    let endWidth;
    let endHeight; // 最终图片高度


    try {
      const end = FileUtils.cutImageInRangeForWidth(imgWidth, imgHeight, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight);
      width = end.width;
      height = end.height;
      endWidth = end.endWidth;
      endHeight = end.endHeight;
    } catch (e) {
      const end = FileUtils.cutImageInRangeForHeight(imgWidth, imgHeight, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight);
      width = end.width;
      height = end.height;
      endWidth = end.endWidth;
      endHeight = end.endHeight;
    }

    if (model === 'center') {
      startX = parseInt((imgWidth - width) / 2, 10);
      startY = parseInt((imgHeight - height) / 2, 10);
    }
    return {
      startX,
      startY,
      width,
      height,
      endWidth,
      endHeight,
    };
  }

  constructor(file) {
    this.file = file;
    this.fileType = file.type;
  }

  /**
   * 裁切图片为指定宽高
   * @param {Number} endWidth 最终的图片宽度
   * @param {Number} endHeight 最终的图片高度
   * @param {Number} quality 图片的质量
   */
  async cutImage(endWidth, endHeight, quality) {
    console.log(endWidth, endHeight);
    const base64Str = await FileUtils.getFileBase64(this.file);
    const imgElem = await FileUtils.Base64ToImage(base64Str);
    console.log(imgElem);
    if (!endHeight) {
      // eslint-disable-next-line
      endHeight = (imgElem.height / imgElem.width) * endWidth;
    }
    if (imgElem.width < endWidth || imgElem.height < endHeight) {
      return Promise.reject(new Error('图片大小不足'));
    }
    const {
      startX, startY, width, height,
    } = FileUtils.computeCutParams(imgElem.width, imgElem.height, endWidth, endHeight, 'center');
    const drawArgs = [startX, startY, width, height, 0, 0, endWidth, endHeight];
    return this.canvasDrawImage(imgElem, endWidth, endHeight, quality, drawArgs);
  }

  /**
   *  在一个 宽高范围内裁剪图片
   * @param {Number} minEndWidth 最终的最小图片宽度
   * @param {Number} minEndHeight 最终的最小图片高度
   * @param {Number} maxEndWidth 最终的最大图片宽度
   * @param {Number} maxEndHeight 最终的最大图片高度
   * @param {Number} quality 图片的质量
   */
  async cutImageInRange(minEndWidth, minEndHeight, maxEndWidth, maxEndHeight, quality) {
    // 最小的宽度 对应 最大的 高度
    // 最大的高度 对应 最小的 宽度
    console.log(minEndWidth, minEndHeight, maxEndWidth, maxEndHeight);
    const base64Str = await FileUtils.getFileBase64(this.file);
    const imgElem = await FileUtils.Base64ToImage(base64Str);
    if (imgElem.width < minEndWidth || imgElem.height < minEndHeight) {
      throw new Error('图片大小不足');
    }
    const {
      startX, startY, width, height, endWidth, endHeight,
    } = FileUtils.computeRangCutParams(imgElem.width, imgElem.height, minEndWidth, minEndHeight, maxEndWidth, maxEndHeight, 'center');
    const drawArgs = [startX, startY, width, height, 0, 0, endWidth, endHeight];
    return this.canvasDrawImage(imgElem, endWidth, endHeight, quality, drawArgs);
  }

  /**
   * 将图片裁剪为固定宽高 并返回base64 字符串
   * @param {Image} image 需要裁剪的Image 对象
   * @param {Number} endWidth 最终的图片宽度
   * @param {Number} endHeight 最终的图片高度
   * @param {Number} quality 图片质量
   * @param {String} drawArgs 裁剪参数列表 调用drawImage 方法的参数数组
   */
  canvasDrawImage(image, endWidth, endHeight, quality = 0.9, drawArgs) {
    if (!drawArgs) {
      throw new Error('drawArgs is required');
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = endWidth;
    canvas.height = endHeight;

    // 在canvas绘制前填充白色背景
    context.fillStyle = '#fff';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // 绘制图片
    // context.drawImage(image, startX, startY, width, height, 0, 0, endWidth, endHeight);
    context.drawImage.apply(context, [image, ...drawArgs]);

    return canvas.toDataURL(this.fileType, quality);
  }
}
