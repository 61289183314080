<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  data() {
    return {};
  },
  
};
</script>

<style>
body {
  background-color: #f5f5f5;
  /* background-color: red; */
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.main-container #AgentIndex{
  padding: 0;
  background-color: #f6f6f6;
}
</style>
