<template>
<el-dialog
    class="center shop-link-qrcode-dialog"
    :visible.sync="visible"
    width="700px"
  >
  <div slot="title" class="dialog-title"> {{title}} </div>
    <img class="mb20 qrcode-img" :src="qrcodeLink">
    <p v-if="showLink" key="oneLink">
      <el-input size="small" class="mb10" :value="link"
        style="width:250px;"></el-input>
      <el-button size="small" @click="copyString(link)">复制</el-button>
    </p>
    <div v-for="(lin,index) in linkList" :key="index" style="text-align: left;padding-left: 30px;margin-bottom: 10px">
      <p style="font-weight: bold;">{{lin.label}}</p>
      <div v-for="(item,indexs) in lin.child" :key="indexs">
        <span>{{item.label}}</span>
        <span size="small" class="mb10"
                  style="margin-right: 8px" readonly="true">{{item.link}}</span>
        <el-button size="small" type="text" @click="copyString(item.link)">复制</el-button>
      </div>
    </div>
    <el-button type="primary" style="margin-top: 10px" @click="close">完成关闭</el-button>
  </el-dialog>
</template>

<script>
import { getQrcodeSrc } from '@/global/globalMethods';
import { copyStringCom } from '@/utils';
import { Message } from 'element-ui';

export default {
  name: 'DialogQrCode',

  data() {
    return {
      visible: false,
      title: '',
      showLink: true,
      link: '',
      qrcodeLink: '',
      linkList: [],
      showMore: false,
    };
  },

  methods: {
    copyString(string) {
      copyStringCom(string, Message.success, Message.error);
    },
    open({
      link, title = '请扫码访问', showLink = true, more, linkList,
    }) {
      this.visible = true;
      console.log(link, title, showLink, more, linkList);
      if (!link && !linkList) {
        return;
      }
      if (more) {
        // console.log(linkList);
        this.showLink = false;
        this.linkList = linkList.map((resp) => {
          // console.log(resp);
          const lin = resp.link;
          const child = [];
          resp.child.forEach((res) => {
            const itemLink = res.link;
            const item = {
              label: res.label,
              link: itemLink.startsWith('http') ? itemLink : location.origin + itemLink,
            };
            child.push(item);
          });
          return {
            label: resp.label,
            child,
            link: lin.startsWith('http') ? lin : location.origin + lin,
          };
        });
        this.link = this.linkList[0].link;
        this.showMore = true;
      } else {
        this.link = link.startsWith('http') ? link : location.origin + link;
        this.showLink = showLink;
        this.showMore = false;
      }
      console.log(this.visible);
      this.title = title;
      this.qrcodeLink = getQrcodeSrc(this.link, 260);
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
.shop-link-qrcode-dialog {
  .dialog-title{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .qrcode-img {
    width: 200px;
    height: 200px;
  }
}
</style>
