
import Vue from 'vue';
import { Message } from 'element-ui';
import { $p_uploadImageBase64, $sp_createPlatPicture } from '@/api/common';
import FileUploader from '@base/utils/file/FileUploader';
import { loadAxiosInterceptors } from '@base/utils/request';
import {
  requestCommonInterceptor,
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
} from '@base/utils/request/axiosInterceptors';
import router from '@/router';
import store from '@/store';
import { getBaseUrl } from '@/config';
import {
  getUserToken,
} from '@/global/tokenManager';



loadAxiosInterceptors([requestCommonInterceptor], [
  responseNeedLoginInterceptor,
  responseNeedBindTelInterceptor,
  responseDataInterceptor,
], {
  router,
  store,
  messageSuccess: Message.success,
  messageError: Message.error,
  loading(loadingFlag) {
    console.log(loadingFlag);
    // if (loadingFlag) {
    //   Toast.loading();
    // }
  },
  toWxLogin() {
    router.push('/login');
  },
  toBindTel() {
    router.push('/login');
  },
  getUserToken,
  getBaseUrl,
  getParamsData() {
    return {};
  },
});

FileUploader.setFileUploadApis({
  uploadApiFn: $p_uploadImageBase64,
  addPlatPicture: $sp_createPlatPicture,
  loadingFn(loadingFlag) {
    console.log(loadingFlag);
    // if (loadingFlag) {
    //   Toast.loading();
    // } else {
    //   Toast.clear();
    // }
  },
});

// 防止重复点击的组件
Vue.component('PrevReclick', {
  abstract: true,
  props: {
    time: {
      type: [String, Number],
      default: 2000,
    },
    events: {
      type: String,
      default: 'click',
    },
  },
  created() {
    this.eventKeys = this.events.split(',');
    this.originMap = {};
    this.debouncedMap = {};
    this.lastTriggerTime = new Date().getTime() - this.time;
  },



  render() {
    if (!this.$slots.default) return null;

    const getEventMiddleware = (callback) => (...params) => {
      const nowTime = new Date().getTime();
      if ((nowTime - this.lastTriggerTime) < this.time) {
        return;
      }
      this.lastTriggerTime = nowTime;
      callback.apply(this, params);
    };

    const vnode = this.$slots.default[0];

    this.eventKeys.forEach((key) => {
      let listeners;
      if (vnode.componentOptions) {
        listeners = vnode.componentOptions.listeners;
      } else {
        listeners = vnode.data.on;
      }
      if (!listeners) return;

      const target = listeners[key];
      if (!target) return;

      if (target === this.originMap[key] && this.debouncedMap[key]) {
        listeners[key] = this.debouncedMap[key];
      } else if (target) {
        this.originMap[key] = target;
        this.debouncedMap[key] = getEventMiddleware(target);
        listeners[key] = this.debouncedMap[key];
      }
    });



    return vnode;
  },
});
