(() => {
  let scale = 1;
  if (window.devicePixelRatio > 1) {
    scale = 0.5;
  }
  const metaEl = document.createElement('meta');
  metaEl.setAttribute('name', 'viewport');
  // ,minimum-scale=${scale}
  metaEl.setAttribute('content', `width=device-width,initial-scale=${scale},maximum-scale=${scale}`);
  console.log(metaEl, 'metaEl', window.innerWidth, scale);
  if (document.documentElement.firstElementChild) {
    document.documentElement.firstElementChild.appendChild(metaEl);
  }
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    document.body.className = 'view-mobile-page';
  }
})();
