/**
 * 验证手机号
 * @param {number} tel 手机号码
 * @returns {boolean} 是否正确
 */
export const verifyTelByMessage = (tel, message = () => {
}) => {
  // const reg = /^1[3-9]\d{9}$/;
  if (!tel) {
    message('请填写手机号');
    return false;
  }
  if (tel.length < 10 || tel.length > 11 || /\D+/.test(tel)) {
    message('请填写正确的手机号');
    return false;
  }
  if (tel[0] === '1' && tel.length === 10) {
    message('请填写正确的手机号');
    return false;
  }
  return true;
};
/**
 * 格式化日期
 * @param {Date} date Date对象
 * @param {string} fmt 返回格式 'yyyy-MM-dd HH:mm:ss SSS q'
 */
export const formatDateByDate = (date, fmt = 'MM-dd HH:mm') => {
  if (date instanceof Date) {
    let format = fmt;
    const o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'H+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    Object.entries(o).forEach(([key, val]) => {
      if (new RegExp(`(${key})`).test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? val : `00${val}`.substr(`${val}`.length));
      }
    });
    return format;
  }
  return date;
};

// 格式化时间戳
export const formatDate = (stamp, fmt = 'yyyy-MM-dd HH:mm') => {
  if (!stamp) {
    return '';
  }
  let date;

  // eslint-disable-next-line radix
  if (parseInt(stamp).toString().length === 10) {
    date = new Date(+stamp * 1000);
  } else {
    date = new Date(+stamp);
  }
 
  
  const resTime = formatDateByDate(date, fmt);
  return resTime;
};

export const formatTimeRangeStr = (rangeStr, simpleFlag) => {
  if (rangeStr.indexOf(',') === -1) return '';
  const [start, end] = rangeStr.split(',');
  const startDate = new Date(+start * 1000);
  const endDate = new Date(+end * 1000);
  return `${formatDateByDate(startDate, simpleFlag ? 'MM-dd HH:mm' : 'yyyy-MM-dd HH:mm')}-${formatDateByDate(endDate, 'HH:mm')}`;
};

// 计算时间差
export const formatHowManyTime = (dateTimeStamp) => {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const now = new Date().getTime();
  const diffValue = now - (dateTimeStamp * 1000);
  const hourC = diffValue / hour;
  const minC = diffValue / minute;
  let result = '';
  if (hourC > 24) {
    result = formatDate(dateTimeStamp, 'yyyy-MM-dd');
  } else if (hourC >= 1) {
    result = `${parseInt(hourC, 10)}个小时前`;
  } else if (minC < 1) {
    result = '刚刚';
  } else {
    console.log(minC);
    result = `${parseInt(minC, 10)}分钟前`;
  }
  return result;
};

export const addUrlOptionsStr = (path, options) => {
  if (options) {
    // 如果存在压缩策略配置
    const optionsType = typeof options;
    if (optionsType === 'string') {
      // 如果是配置的策略
      return `${path}?x-oss-process=image/${options}`;
    } else if (optionsType === 'number') {
      // 如果传入一个数字则默认以该数字， 等比缩放 局中切割
      return `${path}?x-oss-process=image/resize,m_fill,l_${options},s_${options}`;
    } else if (optionsType === 'function') {
      // 如果是一个配置函数
      return `${path}?x-oss-process=image/${options(path)}`;
    }
    // 如果是一个配置对象
    const process = [];
    if (!options.type) {
      options.type = 'resize'; // eslint-disable-line
    }
    Object.keys(options).forEach((opt) => {
      // 对type单独处理
      if (opt !== 'type') {
        process.push(`${opt}_${options[opt]}`);
      }
    });
    return `${path}?x-oss-process=image/${options.type},${process.join(',')}`;
  }
  return path;
};


/**
 * 获取时间戳到当前还有多少天
 * @param {number} times 时间戳
 * @param {boolean} abs 是否取绝对值
 */
export const deadTime = (times, abs) => {
  if (!times) {
    return '';
  }
  const s = times - (Date.now() / 1000);
  let d = s / 3600 / 24;
  if (abs) {
    d = Math.abs(d);
  }
  d = Math.floor(d, 10);
  return d;
};

/**
 * 处理后台查询数据日期间隔
 * @param {String} startDay 开始时间
 * @param {String} endDay 结束时间
 * @param {Boolean} hasToday 是否包含今天, 默认不含
 * @param {Number} interval 日期间隔, 默认 7 天
 */
export const verifyDateInterval = (startDay, endDay, hasToday = false, interval = 7) => {
  const cutDay = hasToday ? 1 : 0;
  // 有日期为空 或 开始时间大于开结束时间
  if ((!startDay || !endDay)
    || (new Date(startDay) - new Date(endDay) - (3600 * 1000 * 24) >= 0)) {
    const now = Date.now();
    const date = [
      formatDateByDate(new Date(now - (3600 * 1000 * 24 * (interval - cutDay))), 'yyyy-MM-dd'),
      formatDateByDate(new Date(now - (3600 * 1000 * 24 * (1 - cutDay))), 'yyyy-MM-dd'),
    ];
    return date;
  }
  return [startDay, endDay];
};

/**
 * 文本复制
 * @param {String} string 要复制的文本
 */
export const copyStringCom = (string, messageSuccess, messageError) => {
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.top = 0;
  textarea.style.left = 0;
  textarea.style.border = 'none';
  textarea.style.outline = 'none';
  textarea.style.resize = 'none';
  textarea.style.background = 'transparent';
  textarea.style.color = 'transparent';

  textarea.value = string;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy');
    messageSuccess('复制成功');
  } catch (err) {
    messageError('复制失败');
  }
  document.body.removeChild(textarea);
};
export const formatMoney = (value) => {
  const money = parseInt((value * 1000), 10) / 1000;
  if (isNaN(money)) {
    return 0;
  }
  const moneyStr = money.toString();
  if (moneyStr.indexOf('.') > -1 && moneyStr.length - moneyStr.indexOf('.') > 3) {
    return Number(money.toFixed(2));
  }
  return money;
};

export const inWxMini = () => {
  if (!window.wx) return false;
  // eslint-disable-next-line
  return window.__wxjs_environment === 'miniprogram';
};
// 头条小程序
export const inTTMini = () => window.navigator.userAgent.toLowerCase().includes('toutiaomicroapp');
// 支付宝小程序
export const inAliMini = () => window.navigator.userAgent.toLowerCase().includes('alipayclient');

// 头条小程序跳转支付页面页面
export const ttMiniToPayPage = (data) => {
  const url = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&');
  // eslint-disable-next-line no-undef
  tt.miniProgram.navigateTo({
    url: `/pages/pay/pay?${url}`,
  });
};

// 支付宝小程序跳转支付页面页面
export const aliMiniToPayPage = (data) => {
  const url = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&');
  // eslint-disable-next-line no-undef
  // const userOpenid = localStorage.getItem('lingdian_o2o_user_openid');
  // eslint-disable-next-line no-undef
  my.reLaunch({
    url: `/pages/pay/pay?${url}`,
  });
};
// 微信小程序跳转页面
export const WxMiniToPage = (option) => {
  wx.miniProgram.navigateTo(option);
};

// 微信小程序跳转支付页面页面
export const WxMiniToPayPage = (data) => {
  const url = Object.keys(data).map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&');
  wx.miniProgram.navigateTo({
    url: `../pay/pay?${url}`,
  });
};

export const sendMessageToMini = (data) => {
  if (inWxMini()) {
    window.wx.miniProgram.postMessage({
      data: {
        time: new Date().getTime(),
        flag: 'share',
        ...data,
      },
    });
  } else if (inTTMini()) {
    // eslint-disable-next-line no-undef
    tt.miniProgram.postMessage({
      data: {
        time: new Date().getTime(),
        flag: 'share',
        ...data,
      },
    });
  }
};

// 不可删
export const noop = () => {};

// 
let formatArea;
export const getFormatAreaByApi = async (getAllAreaApi) => {
  if (formatArea) {
    return formatArea;
  }
  const sessionArea = sessionStorage.getItem('formatArea');
  if (sessionArea) {
    try {
      formatArea = JSON.parse(sessionArea);
      if (formatArea.length > 0) return formatArea;
    } catch (e) {
      console.log(e);
    }
  }
  const data = await getAllAreaApi();
  // areaArray = [...data];
  const formatAreaFn = (child) => {
    child.forEach((c) => {
      const children = [];
      let i = 0;
      let len = data.length;
      while (i < len) {
        const v = data[i];
        if (v.parent_id === c.area_id) {
          children.push(v);
          data.splice(i, 1);
          len -= 1;
        } else {
          i += 1;
        }
      }
      if (children.length === 0) {
        return;
      }
      c.children = children; /* eslint-disable-line*/
      formatAreaFn(children);
    });
  };
  formatAreaFn(data.filter((v) => v.parent_id === ''));
  sessionStorage.setItem('formatArea', JSON.stringify(data));
  formatArea = data;
  return formatArea;
};

export const getAreaValues = (...ids) => {
  if (ids.length === 0 || ids.some((v) => !v)) {
    return [];
  }
  const getAreaValue = (area, index = 0, result = []) => {
    const current = area.find((v) => v.area_id === ids[index]);
    result.push(current);
    if (!current.children) {
      return result;
    }
    return getAreaValue(current.children, index + 1, result);
  };
  return getAreaValue(formatArea);
};

export const getAreaNames = (...ids) => getAreaValues(...ids).map((v) => v.area_name);

// 获取time 11:12 对应的秒数
export const getSeconds = (timeStr, type = 'HH:mm') => {
  if (timeStr === '' || timeStr === null) {
    return '';
  }
  const [hour, minute, second] = timeStr.split(':');
  if (type === 'HH:mm:ss') {
    return (hour * 3600) + (minute * 60) + (second * 1);
  }
  return (hour * 3600) + (minute * 60);
};

// 将seconds 18012 转换为时间 05:12
export const parseSeconds = (second, type = 'HH:mm') => {
  if (second === '') {
    return '';
  }
  let h = parseInt(second / 3600, 10);
  let m = parseInt((second % 3600) / 60, 10);
  let s = parseInt((second % 3600) % 60, 10);
  // 补0
  if (h < 10) {
    h = `0${h}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }
  if (type === 'HH:mm:ss') {
    return `${h}:${m}:${s}`;
  }
  return `${h}:${m}`;
};

export const checkObjectEqual = (origin, obj) => {
  if (origin instanceof Array) {
    if (origin.length !== obj.length) {
      return false;
    }
    if (origin.some((item, index) => !checkObjectEqual(item, obj[index]))) {
      return false;
    }
  } else if (origin instanceof Object) {
    if (Object.entries(origin).some(([key, value]) => !checkObjectEqual(value, obj[key]))) {
      return false;
    }
  } else if (origin !== obj) {
    return false;
  }
  return true;
};

export const HTMLDecode = (text) => {
  const temp = document.createElement('div');
  temp.innerHTML = text;
  const output = temp.innerText || temp.textContent;
  return output;
};

// marketing_type: 1,  // 营销类型,1-减免，2-附加
// marketing_method: 1,// 计算方式：1定额，2比例
export const reduceRuleCompute = (price, rules) => {
  if (!Array.isArray(rules)) return null;
  // 过滤出所有符合条件的规则
  const resultRules = rules.filter((rule) => rule.full <= price);
  let maxReduce = 0;
  let maxReduceIndex = 0;
  resultRules.forEach((item, index) => {
    let { reduce } = item;
    // 如果是百分比减免
    if (item.marketing_method === 2) {
      if (reduce < 10) {
        reduce *= 10;
      }
      reduce = price * (1 - reduce / 100);
    }
    if (reduce > maxReduce) {
      maxReduce = reduce;
      maxReduceIndex = index;
    }
  });
  // console.log('resultRules tttttttttttt', price, resultRules, maxReduce);
  maxReduce = maxReduce.toFixed(2) * 1;
  return {
    reduce: maxReduce,
    rule: resultRules[maxReduceIndex],
    ruleIndex: maxReduceIndex,
  };
};

export const cookie = (name, value, options) => {
  if (typeof value !== 'undefined') { // name and value given, set cookie
    // eslint-disable-next-line
    options = options || {};
    if (value === null) {
      // eslint-disable-next-line
      value = '';
      // eslint-disable-next-line
      options.expires = -1;
    }
    let expires = '';
    if (options.expires && (typeof options.expires === 'number' || options.expires.toUTCString)) {
      let date;
      if (typeof options.expires === 'number') {
        date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
      } else {
        date = options.expires;
      }
      expires = `; expires=${date.toUTCString()}`; // use expires attribute, max-age is not supported by IE
    }
    const path = options.path ? `; path=${options.path}` : '';
    const domain = options.domain ? `; domain=${options.domain}` : '';
    const secure = options.secure ? '; secure' : '';
    document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('');
    return true;
  }
  // only name given, get cookie
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cook = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cook.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cook.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};


// 获取发送短信的链接，android和ios单独处理
export const getSMSHref = (tel, content = '') => {
  if (!content) return `sms:${tel}`;
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isiOS) {
    return `sms:${tel}&body=${content}`;
  }
  return `sms:${tel}?body=${content}`;
};

export const setLocalCache = (key, value) => {
  cookie(key, value, { path: '/', expires: 1000 });
  localStorage.setItem(key, value);
};

export const getLocalCache = (key) => {
  let cache = cookie(key);
  if (cache) return cache;
  cache = localStorage.getItem(key);
  if (cache) return cache;
  return '';
};


export const getStore = (key) => localStorage.getItem(key);
export const setStore = (key, value) => localStorage.setItem(key, value);

// 检查token的状态 0 无效 1 有效 -1 即将过期
export const checkToken = (token) => {
  if (!token) return 0;
  const dataStr = token.split('.')[1];
  let tokenData;
  try {
    tokenData = window.atob(dataStr);
    tokenData = JSON.parse(tokenData);
  } catch (e) {
    console.log(e);
    return 1;
  }
  if (tokenData) {
    const nowTime = (new Date()).getTime();
    const expTime = tokenData.exp * 1000;
    if ((expTime > nowTime) && ((expTime - nowTime) < 30 * 60 * 1000)) {
      return -1;
    }
    if (expTime < nowTime) {
      return 0;
    }
  }
  return 1;
};

/**
 * 将颜色字符串解析为rgb数组
 * @param {String} colorString
 */
export const colorTransformor = (colorString) => {
  if (colorString.startsWith('#')) {
    // 16进制颜色值的正则
    const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    let color = colorString.toLowerCase();
    if (reg.test(color)) {
      // 如果只有三位的值，需变成六位，如：#fff => #ffffff
      if (color.length === 4) {
        let colorNew = '#';
        for (let i = 1; i < 4; i += 1) {
          colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
        }
        color = colorNew;
      }
      // 处理六位的颜色值，转为RGB
      const colorChange = [];
      for (let i = 1; i < 7; i += 2) {
        colorChange.push(parseInt(`0x${color.slice(i, i + 2)}`, 10));
      }
      return colorChange;
    }
  } else if (colorString.startsWith('rgb')) {
    // RGB颜色值的正则
    const reg = /^(rgb|RGB)/;
    const color = colorString;
    if (reg.test(color)) {
      // 把RGB的3个数值变成数组
      const colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',');
      return colorArr.map((item) => parseInt(item, 10));
    }
  }
  // 既不是 rgb 颜色 又不是 十六进制颜色 则是 颜色别名
  const spanElem = document.createElement('span');
  spanElem.style = `color: ${colorString}; width: 20px; height: 20px; display: inline-block;`;
  document.body.appendChild(spanElem);
  const endColorStr = getComputedStyle(spanElem).color;
  document.body.removeChild(spanElem);
  if (!endColorStr) throw new Error('主题色异常');
  console.log(endColorStr);
  const endStr = colorTransformor(endColorStr);
  if (!endStr) {
    throw new Error('主题色异常');
  }
  return endStr;
};

export const loadCatch = (errorEvent) => {
  if (process.env.NODE_ENV === 'production') {
    let reloadNo = sessionStorage.getItem('reload_number');
    if (reloadNo > 3) Promise.reject(errorEvent);
    if (reloadNo >= 1) {
      reloadNo = 1 + Number(reloadNo);
    } else {
      reloadNo = 1;
    }
    sessionStorage.setItem('reload_number', reloadNo);
    window.location.reload(true);
  }
  return Promise.reject(errorEvent);
};
