<template>
  <el-container class="main-container">
    <el-aside :width="isCollapse ? '64px': '230px'" class="left-aside">
      <div class="main-left-slide-head" @click="isCollapse = !isCollapse">
        <img src="/img/main/main_logo_3.png" alt="logo" />
      </div>
      <el-menu class="menu-vertical beautiful-scorll-bar" :unique-opened="true" :default-active="defaultActiveIndex"
        @select="selectMenuHandler" :collapse="isCollapse">
        <template v-for="menuItem in menuList">
          <el-submenu v-if="menuItem.children && menuItem.children.length > 0" :index="menuItem.path" :key="menuItem.path">
            <template slot="title">
              <i v-if="menuItem.icon" :class="menuItem.icon"></i>
              <span slot="title">{{ menuItem.title }}</span>
            </template>
            <el-menu-item-group v-for="secMenu in menuItem.children" :key="secMenu.path">
              <!-- <span slot="title">{{secMenu.title || secMenu.path}}</span> -->
              <el-menu-item :index="secMenu.path">{{
                secMenu.title || secMenu.path
              }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else :index="menuItem.path" :key="menuItem.path">
            <i v-if="menuItem.icon" :class="menuItem.icon"></i>
            <span slot="title">{{ menuItem.title || menuItem.path }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header class="main-header" height="60px">
        <div class="flexbox flex-lr flex-align-center"></div>
        <div>
          <!-- <span class="sx-icon-xiazai download-item" @click="openExportData"></span> -->
          <div class="msg-icon-box pointer">
            <span class="sx-icon-xiaoxi msg-icon" @click="goPage('Notice')"></span>
            <i class="red-badge" v-if="isReadNotice" />
          </div>
          <el-dropdown trigger="click" @command="userInfoDropdownCommand">
            <span class="el-dropdown-link"><i class="sx-icon-yonghu font14 mr5"></i>
              {{agnetUserName}}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown">
              <!-- icon="el-icon-plus"  -->
              <!-- <el-dropdown-item command="changePwd">修改密码</el-dropdown-item> -->
              <el-dropdown-item command="signout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="main-page-content beautiful-horizontal-scorll-bar">
        <div class="center-container beautiful-scorll-bar" :id="$route.name">
          <router-view :key="$route.fullPath" />
        </div>
      </el-main>
    </el-container>
    <el-dialog title="下载中心" :visible.sync="isShoEexportData" center width="70%">
      <div class="red" style="padding-bottom: 15px">
        注意：高峰期间(每日11:00~13:30，16:30~19:00)暂不执行导出任务，低峰期会自动继续处理导出进程
      </div>
      <div>
        <el-pagination background :page-count="totalPages" :current-page.sync="currentPage" @current-change="loadData" class="mb10 left"
          style="display: inline-block">
        </el-pagination>
        <el-button class="right" size="small" type="primary" @click="getExportDataList">
          <i class="iconfont icon-shuaxin" style="font-size: 12px"></i>
          刷新列表
        </el-button>
      </div>
      <el-table :data="exportData" border>
        <el-table-column property="create_time" :formatter="formatTime" label="申请时间" width="200" align="center"></el-table-column>
        <el-table-column label="文件名" :formatter="formatFileName" show-overflow-tooltip header-align="center"></el-table-column>
        <el-table-column label="任务状态" :formatter="formatStatus" width="150" align="center"></el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <a class="down-btn theme-color" :href="
                scope.row.file_url ? scope.row.file_url : scope.row.file_name
              " v-if="scope.row.status == 2">
              下载表格</a>
            <span v-else> --</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="changePwdPopShow" center width="420px">
      <el-form ref="pwdForm" :model="pwd" :rules="pwdRules" label-width="80px" class="phone-change-form">
        <el-form-item label="原始密码" prop="old_password">
          <el-input type="password" v-model="pwd.old_password"></el-input>
        </el-form-item>
        <el-form-item label="新设密码" prop="new_password">
          <el-input type="password" v-model="pwd.new_password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="renew_password">
          <el-input type="password" v-model="pwd.renew_password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer center">
        <el-button type="primary" @click="submitpwdData">确认修改</el-button>
      </span>
    </el-dialog>
    <DialogQrCode ref="dialogQrCode" />
  </el-container>
</template>
<script>
import { getRouteMenu, getRouteByPath } from '@/router/routeMap';
import { setUserToken, getUserToken } from '@/global/tokenManager';
import {
  exportDataType,
  // getWapBaseUrl,
} from '@/config';
import { formatDate, deepClone, checkToken } from '@/utils';
import { $sg_getExportList } from '@/api/statistics';
// import { $sg_getLoginUserRights } from '@/api/rolePermissions';
// import { $sp_saveShopInfo } from '@/api/shop';
import { $sg_getSpreadMainInfo } from '@/api/common';
import { $p_resetPwd } from '@/api/login';
import DialogQrCode from '@/components/common/DialogQrCode.vue';
import { setUserRights } from '@/utils/rights/index';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MainContainer',
  components: {
    DialogQrCode,
  },
  beforeRouteEnter(to, from, next) {
    if (checkToken(getUserToken()) !== 1) {
      next({
        name: 'Login',
      });
      return;
    }
    setUserRights(true, []);
    next();
    // $sg_getLoginUserRights().catch(() => {
    //   setUserRights(false, []);
    // }).then((resp) => {
    //   setUserRights(resp.isSuperAdmin === '1', resp.permission);
    // }).finally(() => {
    //   next();
    // });
  },
  data() {
    const validateRePassPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.pwd.new_password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      agnetUserName: '',
      isCollapse: window.innerWidth < 750,
      menuList: [],
      defaultActiveIndex: '',
      currentActiveIndex: '',
      breadcrumbList: [],
      isShoEexportData: false,
      exportData: [],
      totalPages: 1,
      currentPage: 1,
      changePwdPopShow: false,

      pwd: {
        old_password: '',
        new_password: '',
        renew_password: '',
      },
      pwdRules: {
        old_password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur',
          },
        ],
        new_password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur',
          },
        ],
        renew_password: [
          {
            min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur',
          },
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validateRePassPwd, trigger: 'blur' },
        ],
      },
      businessStatus: '',
    };
  },
  watch: {
    // currentActiveIndex() {
    //   console.log(getRouteByPath(this.currentActiveIndex), 'tttttt', this.currentActiveIndex);
    //   this.breadcrumbList = getRouteByPath(this.currentActiveIndex);
    // },
    $route: {
      handler() {
        console.log(this.$route);
        const pathList = this.$route.path.split('/');
        const list = getRouteByPath(pathList[pathList.length - 1]);
        console.log(list);
        if (list.length > 0) {
          this.defaultActiveIndex = list[list.length - 1].path;
        }
      },
    },
  },
  computed: {
    ...mapState(['isReadNotice']),
  },
  methods: {
    ...mapActions(['updateNotice']),
    goPage(name) {
      this.$router.push({ name });
    },
    selectMenuHandler(menuName, menuPath) {
      console.log('menuName', menuName, 'mp', menuPath);
      const path = `/main/${menuPath.join('/')}`;
      console.log(this.$route, menuName, menuPath);
      this.currentActiveIndex = menuName;
      if (this.$route.path === path) {
        this.$router.replace({
          path,
          query: {
            t: parseInt(Math.random() * 500, 10),
          },
        });
        return;
      }
      this.$router.push({
        path,
      });
    },
    userInfoDropdownCommand(command) {
      switch (command) {
        case 'signout':
          this.signout();
          break;
        case 'changePwd':
          this.changePwdPopShow = true;
          this.$nextTick(() => {
            this.$refs.pwdForm.resetFields();
          });
          break;
        default:
      }
    },
    signout() {
      setUserToken('');
      this.$router.push({
        path: '/login',
        query: {
          spread: this.$store.state.spreadToken,
        },
      });
    },
    async getSpreadMainInfo() {
      const resp = await $sg_getSpreadMainInfo().catch((err) => {
        if (err.code !== 1001) {
          this.signout();
        }
        return Promise.reject(err);
      });

      this.spreadMainInfo = resp;
      this.agnetUserName = resp.agent_info.agent_name || '代理';
      this.$store.commit('mutations_setSpreadToken', resp.token);
      this.$store.commit('mutations_setSpreadMainInfo', resp);
      this.updateNotice();
    },
    // ========================> 下载中心 <========================
    // 打开下载中心
    openExportData() {
      this.isShoEexportData = true;
      this.$store.commit('changeExportTips', false);
      localStorage.removeItem('ExportDataTips');
      this.getExportDataList();
    },
    // 加载数据
    getExportDataList() {
      $sg_getExportList({ page: this.currentPage }).then((res) => {
        console.log('getExportList', res);
        this.totalPages = res.count;
        this.currentPage = +res.current;
        this.exportData = res.data;
      });
    },
    // 格式化时间字段
    formatTime({ create_time }) {
      return formatDate(create_time, 'yyyy-MM-dd HH:mm:ss');
    },
    // 格式化文件名字段
    formatFileName({ file_name }) {
      const tempArr = file_name.split('/');
      return tempArr[tempArr.length - 1];
    },
    // 格式化导出状态字段
    formatStatus({ status }) {
      switch (status) {
        case 1:
          return '导出中';
        case 2:
          return '导出成功';
        case 3:
          return '导出失败';
        default:
      }
      return '--';
    },
    // 格式类型字段
    formatType({ type }) {
      let itemLable = '未知类型';
      const findItem = exportDataType.find((item) => item.type === type);
      if (findItem) {
        itemLable = findItem.label;
      }
      return itemLable;
    },
    // 翻页加载数据
    loadData(val) {
      this.currentPage = val;
      this.getExportDataList();
    },
    // ========================> 下载中心 <========================
    submitpwdData() {
      const self = this;
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          const params = deepClone(self.pwd);
          $p_resetPwd(params).then(() => {
            // console.log(resp);
            self.changePwdPopShow = false;
            this.signout();
          });
        } else {
          console.log('error submit!!');
        }
      });
    },
  },
  created() {
    if (!getUserToken()) {
      this.signout();
      return;
    }
    this.menuList = getRouteMenu();
    this.defaultActiveIndex = this.$route.path.split('/').pop();
    this.currentActiveIndex = this.defaultActiveIndex;
    // this.getSpreadMainInfo();
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";

.main-container {
  min-height: 100vh;
  .main-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .system-logo {
      /*font-size: 24px;*/
      /*font-weight: bold;*/
      /*line-height: 33px;*/
      cursor: pointer;
      width: 123px;
      height: 34px;
      display: inline-block;
      position: relative;
      top: -2px;
    }
    .system-subtitle {
      height: 25px;
      font-size: 18px;
      line-height: 25px;
      padding-left: 15px;
      margin-left: 15px;
      display: inline-block;
      border-left: 1px solid #fff;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .download-item {
      width: 17px;
      height: 25px;
      display: inline-block;
      // background-image: url('/img/main/download.png');
      // background-repeat: no-repeat;
      // background-size: cover;
      vertical-align: middle;
      margin-right: 50px;
      margin-left: 50px;
      cursor: pointer;
      font-size: 16px;
    }
    .msg-icon-box {
      margin-right: 24px;
      position: relative;
      display: inline-block;
      .msg-icon {
        width: 14.65px;
        height: 16.15px;
        color: #999;
      }
      .red-badge {
        width: 6px;
        height: 6px;
        display: inline-block;
        position: absolute;
        background: $--red-color;
        border-radius: 50%;
        top: 1;
        right: 0;
      }
    }
  }
  .left-aside {
    height: 100vh;
    box-shadow: 0 0 6px 0 #ddd;
    transition: width 0.5s cubic-bezier(0.15, 0.99, 0.18, 1.01);
    overflow: hidden;
    background-color: #323542;
    background-image: url("/img/main/tower_slide.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    .main-left-slide-head {
      height: 90px;
      background-color: $--theme-color;
      text-align: center;
      line-height: 90px;
      & > img {
        vertical-align: middle;
        width: 187px;
        height: 58px;
      }
    }
    .el-menu-item-group__title {
      display: none;
    }
    .el-menu-item.is-active {
      background: #2d2e34;
      border-left: 4px solid $--theme-color;
    }
  }
  .main-page-content {
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 15px 14px;
    height: calc(100vh - 62px);
    box-sizing: border-box;
    background-color: #f6f6f6;
  }
  .main-breadcrumb-container {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
    .breadcrumb-icon {
      font-size: 15px;
      margin-right: 10px;
      color: #444;
    }
  }
  .center-container {
    min-width: 1080px;
    position: relative;
    overflow: auto;
    flex: 1;
    border-radius: 6px;
  }
  .menu-vertical {
    height: calc(100% - 90px);
    overflow-y: auto;
    border-right: none;
    user-select: none;
  }
  .beautiful-scorll-bar::-webkit-scrollbar {
    display: none;
  }
  .el-menu [class^="sx-icon-"] {
    vertical-align: middle;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    font-size: 16px;
  }
}
</style>

