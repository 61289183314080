import './logTracker';
import { formatDateByDate } from '../utils';

const logger = new window.Tracker('cn-hangzhou.log.aliyuncs.com', 'songshu-web', 'client-log');

window.logger = logger;

function exportLog(log) {
  const { href } = location;
  if (process.env.VUE_APP_ENV !== 'production') {
    console.log('export log :', log);
    return;
  }
  if (log && log.message && log.message === 'Script error.') return;
  // 循环上报日志key
  Object.keys(log).forEach((key) => {
    if (log[key]) {
      let log_val;
      if (typeof log[key] === 'object') {
        // 如果值是object就转json
        log_val = JSON.stringify(log[key]);
      } else if (typeof log[key] === 'function') {
        // 如果是一个方法 就不尽兴上报
        return;
      } else {
        log_val = log[key];
      }
      logger.push(key, log_val);
    }
  });

  // 添加固定的日志字段 来源，url 和时间
  logger.push('from', href);
  logger.push('target', 'o2o_wap');
  logger.push('time', formatDateByDate(new Date(), 'yyyy-MM-dd HH:mm:ss'));

  // 向服务器提交日志
  logger.logger();
}

export const initLogReport = (Vue) => {
  // 注册一个vue的异常回调handel
  Vue.config.errorHandler = (err, vm, info) => {
    console.error(err);
    console.log(vm, info);
    const log_obj = {};
    if (info) {
      log_obj.u_info = info;
    }
    if (err) {
      log_obj.stack = err.stack;
      log_obj.err_message = err.message;
      log_obj.name = err.name;
      log_obj.code = err.code;
    }
    log_obj.level = 'vue_error';
    exportLog(log_obj);
  };
};

window.onerror = (message, source, lineno, colno, error) => {
  const log_obj = {
    message,
    source,
    lineno,
    colno,
  };
  if (lineno === 0 && colno === 0 && !error) {
    return;
  }
  if (error) {
    log_obj.stack = error.stack;
    log_obj.err_message = error.message;
    log_obj.name = error.name;
  }
  exportLog(log_obj);
};


export default exportLog;
