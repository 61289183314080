import { cookie } from '@/utils';

/**
 * Created by Administrator on 2018/6/9.
 */
let spreadToken = localStorage.getItem('lingdian_o2o_shop_admin_token');
export const setShopAdminToken = (token) => {
  spreadToken = token;
  localStorage.setItem('lingdian_o2o_shop_admin_token', token);
  cookie('auth', token);
  if (window.setKeloopStorage) {
    window.setKeloopStorage('lingdian_o2o_shop_admin_token', token);
  }
};

export const getShopAdminToken = () => {
  if (!spreadToken) {
    let token;
    if (window.getKeloopStorage) {
      token = window.getKeloopStorage('lingdian_o2o_shop_admin_token');
      if (token) return token;
    }
    token = cookie('auth');
    if (token) {
      spreadToken = token;
      if (!localStorage.getItem('lingdian_o2o_shop_admin_token')) {
        localStorage.setItem('lingdian_o2o_shop_admin_token', token);
      }
      return token;
    }
    token = localStorage.getItem('lingdian_o2o_shop_admin_token');
    if (token) {
      spreadToken = token;
      cookie('auth', token);
      return token;
    }
  }
  return spreadToken;
};

export const getShopAdminPermissionStorage = () => {
  let permiss = localStorage.getItem('lingdian_o2o_shop_admin_user_permission');
  if (window.atob) {
    try {
      permiss = window.atob(permiss);
    } catch (e) {
      permiss = [];
    }
    return permiss;
  }
  return permiss;
};
export const setShopAdminPermissionStorage = (permissionList) => {
  console.log(permissionList);
  let permiss;
  if (window.btoa) {
    permiss = window.btoa(permissionList);
  }
  localStorage.setItem('lingdian_o2o_shop_admin_user_permission', permiss);
};

