import Vue from 'vue';
import '@/utils/adminPhoneViewPort';
import 'normalize.css';
import './style/index.scss';
import './assets/iconfont.css';
import './global/globalBootstrap';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/element';
import globalMethods from './global/globalMethods';
import gloablMixin from './global/gloablMixin';
import globalComponents from './global/globalComponents';
import { initLogReport } from './utils/logger/logger';

import '@/utils/rsa';

initLogReport(Vue);

Vue.config.productionTip = false;
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
  Vue.config.performance = true;
}

Vue.use(globalMethods);

Vue.use(globalComponents);

Vue.mixin(gloablMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
