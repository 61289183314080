const userRightCache = {
  isRoot: false,
  userRights: [],
};

export const setUserRights = (isRoot = false, userRights = []) => {
  if (!Array.isArray(userRights)) throw new Error('权限 必须是一个Array');
  userRightCache.isRoot = isRoot;
  userRightCache.userRights = userRights;
};

export const getUserRights = () => ({
  isRoot: userRightCache.isRoot,
  userRights: [...userRightCache.userRights],
});

export const validateEveryRights = (rightsArr = []) => {
  if (!rightsArr) return true;
  if (rightsArr.length === 0) return true;
  return rightsArr.every((item) => userRightCache.userRights.includes(item));
};

export const validateSomeRights = (rightsArr = []) => {
  if (!rightsArr) return true;
  if (rightsArr.length === 0) return true;
  return rightsArr.some((item) => userRightCache.userRights.includes(item));
};

export const validateUserRights = (rightsStr = '', model = 'every') => {
  if (!rightsStr) return true;
  if (userRightCache.isRoot) return true;
  let userRightsArr = rightsStr;
  if (typeof rightsStr === 'string') {
    if (rightsStr.indexOf(',') > -1) {
      userRightsArr = rightsStr.split(',');
    } else {
      return userRightCache.userRights.includes(rightsStr);
    }
  }
  if (model === 'every') {
    return validateEveryRights(userRightsArr);
  } else {
    return validateSomeRights(userRightsArr);
  }
};
