/**
 * 打印订单  只在 壳子中有效
 * @param orderId 订单id 拼接订单类型 打印机类型 下划线分割
 * @param url 获取订单打一模版的url
 * @param options 打印选项
 * @param force 是否强制入队 将跳过入队前的待打一该订单列表中是否存在此订单的判断直接推入队列
 */
export const printView = (orderId, url, options = {}, force = false) => {
  if (!window.printCurrentPage) {
    console.log('不在特定环境中，无法打印订单', url);
    return;
  }
  try {
    window.printCurrentPage({
      url: `${url}&options=${encodeURIComponent(options.printName)}`,
      orderId,
      options,
      force,
    });
  } catch (e) {
    console.log(e);
  }
  console.log(`${url}&options=${encodeURIComponent(options.printName)}`, orderId, options, force);
};

export const printTestView = () => {
  printView(1, `${window.location.origin}/print.html`);
};

window.printTestView = printTestView;
